<template>
  <CCard>
    <CCardBody>
      {{ $t(options.message) }}
    </CCardBody>
    <CCardFooter>
      <CButton type="button" class="mr-2" :color="options.color_yes || 'primary'" @click="onSubmit(options.response_yes || true)">{{ $t(options.yes || 'Yes') }}</CButton>
      <CButton v-if="options.no" type="button" :color="options.color_no || 'default'" @click="onCancel(options.response_no || false)">{{ $t(options.no || 'No') }}</CButton>
      <CButton v-if="options.cancel !== false" type="button" :color="options.color_cancel || 'secondary'" @click="onCancel(options.response_cancel)">{{ $t('Cancel') }}</CButton>
    </CCardFooter>
  </CCard>
</template>

<script>
export default {
  name: 'AModalConfirm',
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClose(response) {
      this.$emit('modalConfirm', response)
    }
  }
}
</script>
